/* Scss Document */

h1,
h2,
h3,
h4,
h5 {
	font-weight: bold;
	font-family: $mainfont;
	color: $darkblue;

	span {
		font-weight: bold;
		font-family: $mainfont;
	}
}

h1 {
	@include font-size(34px);
}

h2 {
	@include font-size(30px);
}

h3 {
	@include font-size(25px);
}

h4 {
	@include font-size(22px);
}

li,
p {
	color: $darkblue;
	font-family: $mainfont;
	margin-bottom: 20px;
	line-height: 1.6;
	font-weight: 400;
}

@media (prefers-color-scheme: dark) {
	li,
	p {
		font-weight: 300;
	}
}

a {
	outline: none;
	color: $darkblue;
	text-decoration: none;
	transition: all 0.5s;

	.no-touchevents &:hover {
		transition: all 0.5s;
	}
}

.button {
	overflow: hidden;
	display: inline-block;
	text-transform: lowercase;
	padding: 15px 20px;
	font-weight: bold;
	background-color: transparent;
	color: $red;
	position: relative;
	text-decoration: none;
	letter-spacing: 0.02em;
	@include font-size(16px);
	border: 1px solid $red;
	white-space: nowrap;
	transform: translateZ(0);

	&:after {
		font-family: $font-awesome-pro;
		content: "\f054";
		display: inline-block;
		margin-left: 14px;
		font-weight: 300;
		transition: transform 0.3s ease-in-out;
	}

	.no-touchevents &:hover {
		color: $darkblue;
		border-color: $darkblue;

		&:after {
			transform: translateX(3px);
		}
	}
}

.red-bg,
.blue-bg {
	.button {
		color: $white;
		border: 1px solid $white;
	}
}

.red-bg {
	.button {
		.no-touchevents &:hover {
			color: $darkblue;
			border-color: $darkblue;
		}
	}
}

.blue-bg {
	.button {
		.no-touchevents &:hover {
			color: $red;
			border-color: $red;
		}
	}
}

span {
	&.red-text {
		color: $red;
	}

	&.blue-text {
		color: $darkblue;
	}
}

p {
	&.small {
		font-size: 0.75rem;
	}

	a {
		color: $red;
		display: inline-block;

		.no-touchevents &:hover {
			color: $darkgrey;
		}
	}
}

hr {
	border: none;
	width: 40px;
	height: 3px;
	margin: 40px auto;
	background-color: $red;
}

.container {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	padding: 0 8%;
	position: relative;

	&.wide {
		max-width: 100%;
		padding: 0 3%;
	}

	&.medium {
		max-width: 1050px;
		padding: 0 20px;
	}

	&.narrow {
		max-width: 850px;
	}
}

.iframe-container {
	overflow: hidden;
	padding-top: 56.25%;
	position: relative;

	iframe {
		border: 0;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

.column-wrap {
	display: flex;
	flex-wrap: wrap;

	&:after {
		content: none;
	}
}

.one-third {
	width: 33.333333%;
}

.two-thirds {
	width: 66.666666%;
}

.two-col {
	width: 48%;
	float: left;
	margin: 0 4% 40px 0;
	clear: right;
}

.two-col.last {
	margin-right: 0;
	padding-right: 0;
}

.three-col {
	.grid-container {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 50px 50px;

		.block {
			.block-image {
				width: 100%;
				margin-bottom: 50px;
				overflow: hidden;

				a {
					position: relative;
					display: block;
					background-size: 140%;
					background-repeat: no-repeat;
					background-position: right bottom;

					&:after {
						content: "";
						display: block;
						padding-bottom: 90%;
					}

					.image {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						width: 100%;
						height: 100%;
						overflow: hidden;
						transition: 0.7s all;
						clip-path: polygon(-1% -1%, -1% 101%, 101% 101%, 101% -1%);

						&:before {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							width: 100%;
							height: 100%;
							opacity: 0;
							background-color: rgba(0, 0, 0, 0.25);
							transition: 0.7s all;
						}

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}

						span {
							color: $white;
							font-weight: bold;
							@include font-size(20px);
							position: absolute;
							top: 50%;
							left: 50%;
							opacity: 0;
							transform: translate(-50%, -50%);
							transition: 0.7s all;

							&:after {
								content: "";
								display: block;
								width: 0;
								height: 2px;
								position: relative;
								top: 3px;
								opacity: 0;
								background-color: $white;
								transition: 0.35s 0.2s all;
							}
						}
					}

					.no-touchevents &:hover {
						.image {
							clip-path: polygon(8% 8%, 8% 92%, 92% 92%, 92% 8%);

							&:before {
								opacity: 1;
							}

							span {
								opacity: 1;

								&:after {
									width: 100%;
									opacity: 1;
								}
							}
						}
					}
				}
			}

			.block-content {
				h3 {
					a {
						color: $darkblue;

						.no-touchevents &:hover {
							color: $red;
						}
					}
				}
			}
		}
	}

	.no-cssgrid & {
		.grid-container {
			display: flex;
			flex-wrap: wrap;

			.block {
				width: 31%;
				float: left;
				margin: 0 3.5% 5% 0;

				&:nth-child(3n + 3) {
					margin-right: 0;
				}
			}
		}
	}
}

.four-col {
	.grid-container {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 50px 50px;
	}

	.no-cssgrid & {
		.grid-container {
			display: flex;
			flex-wrap: wrap;

			.block {
				width: 23%;
				float: left;
				margin: 0 2% 5% 0;

				&:nth-child(4n + 4) {
					margin-right: 0;
				}
			}
		}
	}
}

.indented {
	clear: both;
	width: 100%;
	max-width: 680px;
	margin: 0 auto 60px auto;
}

.no-pad {
	padding: 0;
}
