footer {
	width: 100%;
	clear: both;
	position: relative;
	z-index: 10;
	background-color: $white;

	.footer-top {
		width: 100%;
		height: 90px;
		color: $white;
		background-color: $darkblue;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		.logo {
			padding-left: 35px;

			img {
				max-width: 140px;
			}
		}

		.areas {
			ul {
				li {
					display: inline-block;
					color: $white;
					font-weight: bold;
					margin: 0 12px;
					@include font-size(14px);
				}
			}
		}

		a.up {
			width: 90px;
			height: 90px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-content: center;
			align-items: center;
			background-color: $lightgrey;

			span {
				width: 50px;
				height: 50px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-content: center;
				align-items: center;
				border: 1px solid $red;
				border-radius: 50%;
				transition: 0.4s all;

				i {
					color: $red;
					@include font-size(25px);
					font-family: $font-awesome-pro;
					transition: 0.5s all;
				}
			}

			.no-touchevents &:hover {
				span {
					border-color: $darkblue;
				}

				i {
					color: $darkblue;
				}
			}
		}
	}

	.footer-middle {
		text-align: center;
		padding: 100px 0 50px 0;

		.accreditations {
			ul {
				li {
					display: inline-block;
					margin: 0 20px 20px 20px;

					img {
						vertical-align: middle;
						max-width: 60px;
					}
				}
			}
		}

		.footer-content {
			padding-top: 60px;
			text-align: center;

			p {
				@include font-size(12px);
			}
		}
	}

	.footer-bottom {
		min-height: 90px;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		background-color: $lightgrey;

		.contact {
			color: $white;
			text-align: center;
			height: 100%;
			min-height: inherit;
			width: 320px;
			padding: 10px 30px;
			background-color: $darkblue;
			display: flex;
			flex-wrap: wrap;
			align-content: center;
			align-items: center;
			justify-content: space-between;

			.ie & {
				height: 90px;
			}

			p {
				color: $white;
				margin: auto;
				font-weight: bold;
				@include font-size(14px);
				display: inline-block;
				transition: 0.4s all;
			}

			.tel {
				width: 37px;
				height: 37px;
				display: inline-block;
				background-color: $red;
				border-radius: 50%;
				margin-right: 20px;
				display: flex;
				flex-wrap: wrap;
				align-content: center;
				align-items: center;
				justify-content: center;

				i {
					color: $darkblue;
				}
			}

			.no-touchevents &:hover {
				p {
					color: $red;
				}
			}
		}

		.center {
			display: flex;
			flex-wrap: wrap;

			nav,
			p {
				display: inline-block;
				margin: 0;
			}

			li a,
			p {
				@include font-size(12px);
				vertical-align: top;
			}

			p.sign-off {
				font-weight: bold;
				margin-left: 20px;
			}
		}

		a.up.mobile {
			display: none;
			@include font-size(22px);
		}
	}
}

/*
=================================
IE NOTICE
=================================
*/

.ie10notice {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 9993;
	width: 100%;
	@include font-size(16px);
	font-weight: 400;
	color: $white;
	padding: 60px 30px;
	background-color: #00aadd;

	.logo {
		margin-bottom: 30px;
	}

	a {
		color: $white;
		font-weight: bold;
	}

	h3,
	p {
		color: $white;
		font-weight: normal;

		a:not(.button) {
			color: $white;
		}
	}

	h3 {
		@include font-size(40px);
	}

	strong {
		font-weight: 700;
	}

	ul.important-notice {
		padding: 0;
		list-style-type: 0;
		margin-bottom: 20px;

		li {
			display: inline;
			margin-right: 10px;

			img {
				vertical-align: middle;
			}

			&.uppercase {
				text-transform: uppercase;
				font-size: 1.2rem;
				vertical-align: middle;
			}
		}
	}
}
