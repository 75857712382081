/* Scss Document */
form {
	textarea,
	input[type="tel"],
	input[type="email"],
	input[type="number"],
	input[type="text"],
	input[type="password"] {
		width: 100%;
		padding: 10px 10px 10px 0;
		font-family: $mainfont;
		font-weight: 500;
        @include font-size(16px);
        border: none;
        border-bottom: 1px solid $darkblue;
	}
	
	.select-wrap {
		overflow: hidden;
		background-color: $white;
		position: relative;
		
		&:before {
			content: "";
			width: 25px;
			height: 14px;
			//background: url(../../images/select-arrow.svg) no-repeat;
			position: absolute;
			right: 20px;
			top: 50%;
			margin-top: -5px;
		}
		
		select {
			font-family: $mainfont;
			font-weight: 500;
			@include font-size(16px);
			position: relative;
			z-index: 3;
			cursor: pointer;
			width: 105%;
			height: 100%;
			padding: 16px;
			text-indent: 1px;
			text-overflow: '';
			border: none;
			margin-bottom: 0;
			background-color: transparent;
			@include appearance(none);
		}
	}
	
	textarea {
		resize: none;
		height: 100px;
		max-width: 100%;
		font-weight: 500;
		font-family: $mainfont;
	}
	
	textarea,
	.select-wrap,
	input[type="tel"],
	input[type="email"],
	input[type="number"],
	input[type="text"],
	input[type="password"] {
		margin-bottom: 20px;
		
		&:focus {
			outline: none;
		}
	}
    
    label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
        @include font-size(18px);
    }
	
	input[type="checkbox"] {
		cursor: pointer;
		position: relative;
	}
	
	button,
	input[type="submit"] {
		border: none;
        position: relative;
		cursor: pointer;
        color: $darkblue;
		font-family: $mainfont;
		font-weight: 900;
        line-height: 54px;
		padding: 0 20px;
        @include font-size(15px);
        width: 100%;
        background-color: $white;
        border: 1px solid $darkblue;
        transition: 0.5s all;
        
        &:after {
            font-family: $font-awesome-pro;
            content: "\f054";
            margin-left: 14px;
            font-weight: 300;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }

        .no-touchevents &:hover {
            color: $red;
            border-color: $red;
        }
	}
	
	.wpcf7-form-control-wrap {
		display: block;
	}
	
	::-webkit-input-placeholder {
        color: $darkblue;
        font-family: $mainfont;
		font-weight: 500;
        @include font-size(14px);
	}

	::-moz-placeholder {
        color: $darkblue;
        font-family: $mainfont;
		font-weight: 500;
        @include font-size(14px);
	}

	:-ms-input-placeholder {
        color: $darkblue;
        font-family: $mainfont;
		font-weight: 500;
        @include font-size(14px);
	}

	:-moz-placeholder {
        color: $darkblue;
        font-family: $mainfont;
		font-weight: 500;
        @include font-size(14px);
	}
	
	.checkbox {
		margin-bottom: 20px;
        
        &.gdpr-terms {
            margin: 20px 0;
        }
		
		.wpcf7-form-control-wrap {
			float: left;
			display: inline-block;
		}
		
		.wpcf7-list-item {
            width: 100%;
			margin: 0 10px 20px 0;

            label {
                font-weight: 500;
                @include font-size(12px);
                
                a {
                    font-weight: 500 !important;
                    
                    .no-touchevents &:hover {
                        color: $red;
                    }
                }
                
                input[type="checkbox"] {
                    display: inline-block;
                    margin: 0 8px 5px 0;
                    position: relative;
                    vertical-align: sub;
                }

			    span.wpcf7-list-item-label {
                    display: inline-block;
                    margin-left: 5px;
                }
			}
		}
	}
	
	/* RESPONSE/ERRORS */
	.ajax-loader,
	.ajax-loader:after {
		border-radius: 50%;
		width: 22px !important;
		height: 22px !important;
	}

	.ajax-loader {
		clear: both;
		display: block;
		position: relative;
    	left: 0;
        top: 10px;
        text-indent: -9999em;
		background-image: none !important;
		border-top: 4px solid $lightgrey !important;
		border-right: 4px solid $lightgrey !important;
		border-bottom: 4px solid $lightgrey !important;
		border-left: 4px solid $red !important;
        transform: translateZ(0);
		animation: loading 1s infinite linear;
	}
	
	span.wpcf7-not-valid-tip {
		display: none;	
	}
	
	input[type="checkbox"] + span.wpcf7-not-valid-tip {
		display: block;
		margin-bottom: 5px;
	}

	div.wpcf7-response-output {
		margin: 30px 0 !important;
        padding: 12px !important;
		width: 100%;
        border-radius: 0;
		font-family: $mainfont;
        @include font-size(14px);
	}

	textarea.wpcf7-not-valid,
	select.wpcf7-not-valid,
	form .select-wrap select.wpcf7-not-valid,
	input[type="tel"].wpcf7-not-valid, 
	input[type="email"].wpcf7-not-valid, 
	input[type="text"].wpcf7-not-valid {
		color: #0D2240;
		opacity: 1;
		background-color: #ffd4d4;
		border-color: red;
	}

	.invalid .focus-input {
		background-color: #ffd4d4;
	}
	
	div.wpcf7-mail-sent-ng,
	div.wpcf7-validation-errors {
		border: 2px solid red;
		background-color: #ffd4d4;
		width: 100%;
		padding: 16px 20px;
		color: #ec6c6c;
		color: red;
	}

	div.wpcf7-mail-sent-ok {
		padding: 20px;
		border: 2px solid #55d2a6;
		margin-bottom: 20px;
		position: relative;
		background-color: #f2fffa;
		color: #0D2240;
	}
}

.blue-bg,
.form-banner {
    form {
        color: $white;
        padding-top: 30px;
        
        a {
           color: $white !important;
            
            .no-touchevents &:hover {
                color: $red !important;
            }
        }
        
        textarea,
        input[type="tel"],
        input[type="email"],
        input[type="number"],
        input[type="text"],
        input[type="password"] {
            color: $white;
            border-color: $white;
            margin-bottom: 30px;
            background-color: transparent;
        }
        
        ::-webkit-input-placeholder {
            color: $white;
        }

        ::-moz-placeholder {
            color: $white;
        }

        :-ms-input-placeholder {
            color: $white;
        }

        :-moz-placeholder {
            color: $white;
        }
        
        button,
        input[type="submit"] {
            color: $white;
            background-color: transparent;
            border: 1px solid $white;

            .no-touchevents &:hover {
                color: $red;
                border-color: $red;
            }
        }
    }
}