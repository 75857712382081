header {
	nav {
		ul {
			li {
				float: left;
				display: inline-block;
				margin: 0 10px;
				position: relative;
				line-height: 1.2;

				a {
					color: $darkblue;
					display: inline-block;
					text-transform: uppercase;
					font-weight: bold;
					font-family: $mainfont;
					letter-spacing: normal;
					padding-bottom: 12px;
					@include font-size(14px);

					.no-touchevents &:hover {
						color: $red;
					}
				}

				&.level-0.active-hover,
				.single-post &.blog,
				&.current-page-ancestor,
				&.current-menu-item {
					> a {
						color: $red;
					}
				}

				.sub-wrap {
					position: absolute;
					left: 0;
					top: 80px;
					visibility: hidden;
					opacity: 0;
					width: 250px;
					text-align: left;
					background-color: $white;
					box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.05);
					transition: 0.5s all;

					ul {
						padding: 10px 0;

						li {
							position: relative;
							text-align: left;
							margin: 0;
							display: block;
							width: 100%;
							padding: 0;

							&.level-1.menu-item-has-children {
								position: relative;

								&:after {
									position: absolute;
									font-family: $font-awesome-pro;
									top: 10px;
									right: 15px;
									content: "\f054";
									color: $darkblue;
									@include font-size(12px);
								}
							}

							&.current-menu-item {
								> a {
									color: $red;
								}
							}

							a {
								padding: 0;
								color: $darkblue;
								text-transform: none;
								letter-spacing: 0.04rem;
								padding: 8px 20px;
								@include font-size(14px);

								&:after {
									content: none;
								}

								.no-touchevents &:hover {
									color: $red;
								}
							}

							> .sub-wrap {
								top: -10px;
								left: 120%;

								.sub-wrap {
									display: none !important;
									visibility: hidden;
								}
							}

							.no-touchevents &:hover {
								.sub-wrap {
									top: -10px;
									left: 100%;
								}
							}
						}
					}
				}

				.no-touchevents &:hover > .sub-wrap {
					visibility: visible;
					opacity: 1;
					top: 30px;
					z-index: 1;
				}
			}
		}
	}
}

.mobile-menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 500;
	text-align: center;
	color: $white;
	overflow: auto;
	background-color: $darkblue;
	transform: translateY(-100%);
	transition: 0.6s all;

	&.active {
		transform: translateY(0);
	}

	.menu-header {
		position: relative;
		height: 150px;
		padding: 20px 30px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.close {
			display: inline-block;
			cursor: pointer;
			width: 32px;
			height: 32px;
			transition: 0.3s all;

			&:before,
			&:after {
				content: "";
				background-color: $white;
				position: relative;
				top: 0;
				width: 100%;
				height: 3px;
				display: block;
				margin: 0 auto 6px auto;
			}

			&:before {
				top: 15px;
				transform: rotate(45deg);
			}

			&:after {
				top: 6px;
				transform: rotate(-45deg);
			}
		}

		.logo {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			height: auto;
			width: 130px;
			padding-top: 15px;

			a.mobile-emergency {
				display: block;
				margin-bottom: 20px;
				text-transform: uppercase;
				color: $white;
				font-weight: bold;
				line-height: 1.2;
				letter-spacing: normal;
				@include font-size(13px);

				.no-touchevents &:hover {
					color: $red;
				}
			}
		}

		.telephone.mobile-tel {
			display: inline-block;
			align-self: flex-start;

			a {
				width: 38px;
				height: 38px;
				padding: 0;
				color: $darkblue;
				background-color: $white;
				text-align: center;
				border-radius: 50%;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;

				.no-touchevents &:hover {
					background-color: $red;
				}
			}
		}
	}

	.menu-wrap {
		padding-top: 20px;

		nav {
			ul {
				li {
					position: relative;

					&.current-page-ancestor,
					&.current-menu-item {
						> a {
							color: $red;
						}
					}

					a {
						color: $white;
						font-weight: bold;
						letter-spacing: normal;
						@include font-size(14px);
						text-transform: uppercase;

						.no-touchevents &:hover {
							color: $red;
						}
					}

					.menu-expand {
						position: absolute;
						right: 15px;
						top: 0px;
						width: 25px;
						height: 25px;
						cursor: pointer;
						transition: 0.3s all;

						&.active {
							transform: rotate(-180deg);
						}

						i {
							color: $white;
						}
					}

					ul.sub-menu {
						display: none;
						padding: 15px 0 0 0;

						li {
							margin-bottom: 15px;

							&:last-child {
								margin-bottom: 0;
							}

							a {
								font-weight: 500;
								text-transform: none;
							}
						}
					}
				}
			}
		}
	}
}

.terms-menu {
	margin-right: 20px;

	ul {
		li {
			margin: 0 10px;
			display: inline-block;

			&.current-menu-item {
				a {
					color: $red;
				}
			}

			a {
				color: $darkblue;

				.no-touchevents &:hover {
					color: $red;
				}
			}
		}
	}
}

.social-menu {
	margin-right: 30px;

	ul {
		li {
			margin-right: 8px;
			display: inline-block;
			padding: 0;

			&:before {
				content: none !important;
			}

			&:last-child {
				margin-right: 0;
			}

			a {
				width: 36px;
				height: 36px;
				display: block;
				color: $red;
				border: 1px solid $red;
				border-radius: 50%;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;

				i {
					margin: 1px !important;
					position: relative;
					top: 1px;
				}

				.no-touchevents &:hover {
					color: $darkblue;
					border-color: $darkblue;
				}
			}
		}
	}
}

footer {
	nav {
		ul {
			li {
				display: inline-block;
				margin: 0;
				line-height: 1em;

				a {
					font-weight: bold;
				}
			}
		}

		&.footer-menu {
			margin-bottom: 60px;

			ul {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
				grid-gap: 50px 50px;

				.no-cssgrid & {
					display: flex;
					flex-wrap: wrap;

					li {
						width: 16.666666%;
					}
				}

				li {
					margin: 0;
					text-align: left;
					display: inline-block;
					float: left;
					padding-right: 20px;

					&:last-child {
						margin-right: 0;
					}

					a {
						text-transform: uppercase;
						color: $darkblue;
						font-weight: bold;
						@include font-size(14px);

						.no-touchevents &:hover {
							color: $red;
						}
					}

					ul.sub-menu {
						padding-top: 20px;
						display: block;

						li {
							display: block;
							float: none;
							width: 100%;
							padding-right: 0;
							margin-bottom: 12px;

							a {
								text-transform: none;
								@include font-size(13px);
							}
						}
					}
				}
			}
		}
	}
}
