.page-section {
	width: 100%;
	clear: both;
	position: relative;
	padding: 120px 0;

	&.no-pad {
		padding: 0;
	}

	blockquote {
		p {
			color: $red;
			font-weight: bold;
			font-style: italic;
			@include font-size(20px);
		}
	}

	ul,
	ol {
		margin-bottom: 40px;

		li {
			color: $black;
			font-weight: 400;
			margin-bottom: 10px;
			position: relative;
			padding-left: 20px;
			@include font-size(16px);

			> ul {
				margin: 10px 0;
			}

			a {
				display: inline-block;

				.no-touchevents &:hover {
					color: $red;
				}
			}
		}
	}

	ul li:before {
		content: "";
		position: absolute;
		display: block;
		width: 5px;
		height: 5px;
		top: 10px;
		left: 0px;
		background-color: $red;
		border-radius: 50%;
	}

	ol {
		counter-reset: count-me;

		li {
			list-style-type: none;
			counter-increment: count-me;
			position: relative;
			padding-left: 20px;

			&:before {
				content: counter(count-me) ". ";
				position: absolute;
				left: 0;
				top: 0;
				font-weight: bold;
				color: $red;
				@include font-size(16px);
			}
		}
	}

	.table-wrap {
		width: 100%;
		clear: both;
		margin: 40px 0;
	}

	table {
		width: 100%;
		border-collapse: collapse;

		td {
			color: $darkblue;
			font-family: $mainfont;
			background-color: $white;
			border: 1px solid $darkblue;
		}

		thead {
			td {
				padding: 10px;
				font-weight: bold;
				background-color: $lightgrey;
			}
		}

		tbody {
			tr {
				td {
					padding: 10px;
				}
			}
		}
	}

	&.basic-content {
		p {
			img {
				margin: 20px 0;
			}

			a {
				&:after {
					content: none;
				}
			}
		}
	}

	&.services-section {
		width: 100%;
		clear: both;
		padding: 120px 0;
		position: relative;
		background-color: transparent;

		.container {
			position: relative;
			z-index: 10;
		}

		.content-wrap {
			.section-content {
				width: 100%;
				max-width: 50%;
				padding-right: 40px;

				h2 {
					line-height: 1.4em;
					color: $darkblue;
					@include font-size(34px);
				}

				h3 {
					color: $darkblue;
					line-height: 1.4em;
					@include font-size(20px);
				}
			}

			.service-icons {
				width: 100%;
				clear: both;
				padding: 50px 0;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.icon {
					display: inline-block;
					float: left;
					text-align: center;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;

					a {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;

						.no-touchevents &:hover {
							.title {
								color: $red;
							}
						}
					}

					.icon-img {
						width: 100%;

						img {
							margin-bottom: 20px;
						}
					}

					p {
						align-self: flex-end;
						margin-bottom: 10px;
						font-weight: bold;
						@include font-size(14px);

						&.title {
							transition: 0.5s all;
						}
					}
				}
			}
		}

		&.has-bg-image {
			background-attachment: fixed;

			.touchevents .os-ios & {
				background-attachment: initial;
			}

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				mix-blend-mode: multiply;
			}

			.ie &,
			.no-cssfilters {
				&:before {
					opacity: 0.65;
				}
			}

			&.blue-overlay {
				&:before {
					background-color: $darkblue;
				}

				a.button {
					.no-touchevents &:hover {
						color: $red;
						border-color: $red;
					}
				}

				.icon {
					a {
						.no-touchevents &:hover {
							.title {
								color: $red;
							}
						}
					}
				}
			}

			&.red-overlay {
				&:before {
					background-color: $red;
				}

				a.button {
					.no-touchevents &:hover {
						color: $darkblue;
						border-color: $darkblue;
					}
				}

				.icon {
					a {
						.no-touchevents &:hover {
							.title {
								color: $darkblue;
							}
						}
					}
				}
			}

			&.grey-overlay {
				&:before {
					background-color: #5d5d5d;
				}

				a.button {
					.no-touchevents &:hover {
						color: $red;
						border-color: $red;
					}
				}

				.icon {
					a {
						.no-touchevents &:hover {
							.title {
								color: $red;
							}
						}
					}
				}
			}

			.section-content {
				h2,
				h3,
				h4,
				h5,
				p,
				li,
				a {
					color: $white;
				}
			}

			.service-icons {
				.icon {
					p {
						color: $white;
					}
				}
			}

			a.button {
				color: $white;
				border-color: $white;
			}
		}
	}

	&.image-content-blocks {
		background-color: $lightgrey;

		&:before {
			content: "";
			display: block;
			width: 18%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: $white;
		}

		.section-content {
			width: 100%;
			max-width: 50%;
		}

		.blocks-container {
			width: 100%;
			padding: 70px 0;

			.block {
				width: 100%;
				clear: both;
				position: relative;
				display: flex;
				flex-wrap: wrap;

				.block-images {
					width: 80%;
					position: relative;

					.block-image {
						vertical-align: top;
						position: relative;
						height: 80vh;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							font-family: "object-fit: cover;";
						}
					}

					.progress-bar {
						width: 100%;
						height: 15px;
						position: absolute;
						bottom: 0;
						left: 0;
						z-index: 10;

						.progress {
							height: 100%;
						}
					}
				}

				.block-content {
					text-align: center;
					padding: 5%;
					width: 100%;
					max-width: 38%;
					z-index: 20;
					position: absolute;

					h2,
					h3,
					h4,
					h5,
					p,
					li,
					a:not(.button) {
						color: $white;
					}

					h2,
					h3 {
						@include font-size(34px);
					}

					p {
						line-height: 1.4em;
						font-weight: bold;
						@include font-size(20px);
					}

					a.button {
						margin-top: 50px;
					}
				}

				&.red-bg {
					padding-bottom: 120px;
					justify-content: flex-end;

					.block-content {
						left: 0;
						bottom: 0;
						background-color: $red;
					}

					.block-images {
						.progress-bar {
							.progress {
								background-color: $red;
							}
						}
					}
				}

				&.blue-bg {
					padding-top: 120px;
					justify-content: flex-start;

					.block-content {
						top: 0;
						right: 0;
						background-color: $darkblue;
					}

					.block-images {
						.progress-bar {
							.progress {
								background-color: $darkblue;
							}
						}
					}
				}
			}
		}
	}

	&.three-col-link-blocks {
		background-color: transparent;

		.section-heading {
			width: 100%;
			max-width: 50%;
			margin-bottom: 50px;
		}
	}

	&.accreditations-section {
		width: 100%;
		background-color: $white;

		&.text-align-left {
			text-align: left;
		}

		&.text-align-center {
			text-align: center;

			.section-heading {
				margin: 0 auto 80px auto;
			}

			.section-content {
				margin: 0 auto;
			}
		}

		.section-heading {
			margin-bottom: 80px;
			width: 100%;
			max-width: 50%;

			h2 {
				line-height: 1.4em;
			}
		}

		.accreditations {
			width: 100%;
			text-align: center;
			margin-bottom: 30px;

			ul {
				display: inline-block;

				li {
					padding: 0;
					margin: 0 20px;
					text-align: center;
					display: inline-block;

					&:before {
						content: none;
					}

					img {
						max-width: 120px;
						margin-bottom: 20px;
					}

					.title {
						p {
							margin: 0;
							font-weight: bold;
							@include font-size(12px);
						}
					}
				}
			}
		}

		.section-content {
			width: 100%;
			max-width: 50%;

			h3 {
				line-height: 1.4em;
				@include font-size(20px);
			}

			a.button {
				margin-top: 20px;
			}
		}
	}

	&.submit-story {
		padding-top: 20px;

		.two-col {
			padding: 0 8%;

			&.last {
				padding: 0;
			}
		}
	}

	&.locations {
		padding-top: 50px;

		.location {
			width: 100%;
			display: flex;
			flex-wrap: wrap;

			.location-content {
				padding: 5%;
				display: flex;
				flex-wrap: wrap;

				.content {
					padding-right: 10%;

					p {
						font-weight: 400;

						a:not(.button) {
							color: $red;
							font-weight: 400;
						}
					}
				}
			}

			.map {
				background-color: $lightgrey;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					font-family: "object-fit: cover;";
				}
			}

			&:nth-child(odd) {
				flex-direction: row;

				.location-content {
					background-color: $darkblue;

					h2,
					h3,
					h4,
					h5,
					p,
					li,
					a:not(.button) {
						color: $white;
					}

					p {
						font-weight: 300;
					}

					a.button {
						font-weight: 500;
					}
				}
			}

			&:nth-child(even) {
				flex-direction: row-reverse;

				.location-content {
					background-color: $white;

					h2,
					h3,
					h4,
					h5,
					p,
					li,
					a:not(.button) {
						color: $darkblue;
					}
				}
			}

			&.no-map {
				.location-content {
					width: 100%;
				}

				.map {
					width: 0;
					display: none;
				}
			}
		}
	}

	&.faqs-section {
		padding-top: 0;

		.faqs {
			width: 100%;
			animation: fadeIn 1s;
			animation-delay: 0.3s;
			animation-duration: 1s;
			animation-fill-mode: both;

			.faq {
				cursor: pointer;
				position: relative;
				padding: 30px 60px 30px 30px;
				margin-bottom: 30px;
				border: 1px solid $darkblue;
				background-color: $white;

				.question,
				.answer {
					span {
						color: $red;
						font-weight: bold;
					}

					p {
						font-weight: bold;
						display: inline;
					}
				}

				.answer {
					display: none;
					padding-top: 30px;
				}

				.toggle {
					position: absolute;
					top: 30px;
					right: 30px;
					transition: 0.4s all;

					i {
						color: $red;
						@include font-size(18px);
					}
				}

				&.active {
					.toggle {
						transform: rotate(180deg);
					}
				}
			}
		}
	}

	&.logo-blocks {
		background-color: $white;

		.section-heading {
			margin-bottom: 20px;
		}

		.block {
			.block-image {
				width: 100%;
				height: 100%;
				margin-bottom: 0;
				overflow: hidden;

				.image {
					height: inherit;
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;

					a {
						display: block;
					}

					img {
						width: 100%;
						max-width: 250px;
					}
				}
			}
		}
	}

	&.contact-section {
		width: 100%;
		padding: 0;
		margin-top: 20px;

		.col {
			width: 50%;
			padding: 6%;

			&.white-bg {
				background-color: $white;
			}

			&.blue-bg {
				background-color: $darkblue;
			}

			h1 {
				margin-bottom: 50px;
			}

			p {
				font-weight: 400;
			}

			.social-menu {
				margin-top: 50px;
			}
		}
	}

	&.certificates-section {
		.certificates-row {
			margin-bottom: 50px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.map-section {
		width: 100%;

		.map {
			position: relative;
		}
	}
}

.page-section.three-col-link-blocks + .page-section.accreditations-section,
.page-section.basic-content + .page-section.three-col-link-blocks,
.page-section.three-col-link-blocks + .page-section.three-col-link-blocks,
.page-section:not(.solid-bg) + .page-section:not(.solid-bg):not(.three-col-link-blocks) {
	padding-top: 0;
}

.text-banner + .page-section {
	padding-top: 50px;

	&.faqs-section {
		padding-top: 0;
	}
}
