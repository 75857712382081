@media screen and (max-width: 1500px) {
	.container {
		padding: 0 5%;
	}

	.page-section {
		&.services-section {
			.content-wrap {
				.service-icons {
					justify-content: flex-start;

					.icon {
						width: 20%;
						margin-bottom: 30px;
					}
				}
			}
		}

		&.image-content-blocks {
			.blocks-container {
				.block {
					.block-content {
						max-width: 45%;
					}
				}
			}
		}

		&.accreditations-section {
			.accreditations {
				text-align: left;

				ul {
					li {
						margin-bottom: 30px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1400px) {
	header {
		.header-col {
			&.header-left {
				justify-content: flex-end;

				.header-icon {
					display: none;
				}
			}
		}
	}
}

@media screen and (max-width: 1250px) {
	header {
		.logo {
			position: relative;
			margin: 0 20px 0 0;
			width: 125px;
			height: 51px;
		}

		.header-col {
			width: auto;

			&.header-left,
			&.header-right {
				padding: 0;
			}
		}

		nav {
			ul {
				li {
					a {
						@include font-size(13px);
					}
				}
			}
		}

		.telephone {
			&.desktop-tel {
				display: none;
			}

			&.mobile-tel {
				display: inline-block;
				align-self: flex-start;
				position: absolute;
				right: 30px;
			}
		}
	}

	.main-banner {
		&.form-banner,
		&.image-banner {
			.banner-image {
				padding: 0;
			}

			.banner-content {
				max-width: 50%;

				h1 {
					@include font-size(28px);
				}
			}
		}

		&.form-banner {
			.banner-content {
				max-width: 80%;
			}
		}
	}

	h1 {
		@include font-size(28px);
	}

	.page-section {
		&.locations {
			.location {
				.location-content {
					width: 50%;

					.col {
						width: 100%;
						padding-right: 0;

						p {
							@include font-size(15px);
						}
					}
				}

				.map {
					width: 50%;
				}
			}
		}

		&.submit-story {
			.two-col {
				padding: 0 8% 0 0;
			}
		}
	}

	footer {
		nav {
			&.footer-menu {
				ul {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					grid-gap: 50px 50px;

					li {
						width: auto;
						margin: 0;

						ul {
							display: block;
						}
					}
				}
			}
		}

		.footer-bottom {
			padding-bottom: 30px;

			.contact,
			.center,
			.social-menu {
				width: 100%;
				text-align: center;
				align-items: center;
				justify-content: center;
			}

			.social-menu {
				margin-right: 0;
			}

			.center {
				padding: 30px 0;

				nav.terms-menu {
					width: 100%;
					display: block;
					margin: 0 0 20px 0;
				}

				p.sign-off {
					margin-left: 0;
				}
			}

			.contact {
				width: 100%;
				text-align: center;
				justify-content: center;

				p {
					margin: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 1180px) {
	footer {
		.footer-top {
			height: auto;
			min-height: 90px;
			padding: 0 90px 0 30px;
			position: relative;
			justify-content: flex-start;

			.logo {
				display: none;
			}

			a.up {
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
}

@media screen and (min-width: 1050px) {
	.mobile-menu {
		transform: translateY(-100%) !important;
	}
}

@media screen and (max-width: 1050px) {
	header {
		height: 150px;

		.header-col {
			display: none;
		}

		.logo {
			position: absolute;
			margin: auto;
			height: auto;
			padding-top: 15px;

			a.mobile-emergency {
				display: block;
			}
		}

		.hamburger {
			display: block;
		}
	}

	.page-wrap {
		padding-top: 150px;
	}

	.main-banner {
		padding-top: 0;

		.banner-content {
			p {
				@include font-size(16px);
			}
		}

		&.form-banner,
		&.image-banner {
			.banner-content {
				p {
					@include font-size(16px);
				}
			}
		}

		&.form-banner,
		&.image-banner {
			height: auto;

			.banner-image {
				height: auto;
				margin-bottom: 20px;
			}

			.banner-content {
				max-width: 100%;
				width: 100%;
				position: static;
				bottom: auto;
				padding: 5% 30px;
				margin: 0;
			}
		}

		&.text-banner {
			padding: 30px 0;

			.banner-content {
				max-width: 100%;
				padding-right: 0;
			}
		}

		&.post-banner {
			.banner-content {
				.social-share {
					position: static;
					top: auto;
					left: auto;

					ul {
						li {
							display: inline-block;
							margin: 0 10px 15px 0;
						}
					}
				}
			}
		}
	}

	h2 {
		@include font-size(28px);
	}

	h3 {
		line-height: 1.25;
		@include font-size(25px);
	}

	.container {
		padding: 0 30px;

		&.wide {
			padding: 0 30px;
		}
	}

	.three-col {
		.grid-container {
			grid-template-columns: 1fr 1fr;

			.no-cssgrid & {
				.block {
					width: 48%;
					margin: 0 4% 4% 0;

					&:nth-child(3n + 3) {
						margin: 0 4% 4% 0;
					}

					&:nth-child(2n + 2) {
						margin-right: 0;
					}
				}
			}
		}
	}

	.four-col {
		.grid-container {
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 20px;

			.no-cssgrid & {
				.grid-container {
					.block {
						width: 31%;
						margin: 0 3.5% 5% 0;

						&:nth-child(4n + 4) {
							margin: 0 3.5% 5% 0;
						}

						&:nth-child(3n + 3) {
							margin-right: 0;
						}
					}
				}
			}
		}
	}

	.page-section {
		padding: 80px 0;

		&.services-section {
			padding: 80px 0;
		}

		&.services-section {
			.content-wrap {
				.section-content {
					max-width: 100%;
					padding-right: 0;

					h2 {
						@include font-size(28px);
					}
				}

				.service-icons {
					flex-wrap: initial;
					display: grid;
					grid-template-columns: 1fr 1fr 1fr 1fr;
					grid-gap: 30px 30px;

					.icon {
						width: auto;
					}
				}
			}
		}

		&.image-content-blocks {
			.section-content {
				max-width: 100%;
			}

			.blocks-container {
				.block {
					margin-bottom: 80px;

					&:last-child {
						margin-bottom: 0;
					}

					&.red-bg,
					&.blue-bg {
						padding: 0;
					}

					.block-images,
					.block-content {
						width: 100%;
						max-width: 100%;
					}

					.block-content {
						position: static;
						order: 2;
						margin: -65px 30px 0 30px;

						h2,
						h3 {
							@include font-size(25px);
						}

						p {
							@include font-size(16px);
						}
					}

					.block-images {
						order: 1;

						.block-image {
							height: auto;

							img {
								object-fit: none;
							}
						}

						.progress-bar {
							bottom: auto;
							top: 0;
						}
					}
				}
			}
		}

		&.accreditations-section {
			.section-heading,
			.section-content {
				max-width: 100%;
			}

			.accreditations {
				ul {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr 1fr;
					grid-gap: 30px 30px;

					li {
						margin: 0;
					}
				}
			}
		}

		&.three-col-link-blocks {
			.section-heading {
				max-width: 100%;
			}
		}

		&.locations {
			.location {
				margin-bottom: 30px;

				&.white-bg {
					.location-content {
						padding: 5% 0;
					}
				}

				.map,
				.location-content {
					width: 100%;
				}

				.location-content {
					padding: 5% 30px;
				}
			}
		}

		&.contact-section {
			.column-wrap {
				.col {
					width: 100%;
					padding: 5% 30px;
				}
			}
		}
	}

	form {
		padding: 30px 0;

		.two-col {
			width: 100%;
		}

		button,
		input[type="submit"] {
			width: auto;

			&:after {
				position: static;
				top: auto;
				right: auto;
				transform: none;
			}
		}
	}
}

@media screen and (max-width: 820px) {
	header {
		.hamburger {
			left: 20px;
		}

		.telephone.mobile-tel {
			right: 20px;
		}
	}

	.mobile-menu {
		.menu-header {
			padding: 20px;
		}
	}

	.main-banner {
		&.form-banner,
		&.image-banner {
			.banner-content {
				padding: 30px 20px;
			}
		}
	}

	.container {
		padding: 0 20px;

		&.wide {
			padding: 0 20px;
		}
	}

	.page-section {
		&.image-content-blocks {
			.blocks-container {
				.block {
					.block-content {
						margin: -65px 20px 0 20px;
					}
				}
			}
		}

		&.contact-section {
			.column-wrap {
				.col {
					padding: 30px 20px;
				}
			}
		}

		&.locations {
			.location {
				.location-content {
					padding: 30px 20px;
				}

				&.white-bg {
					.location-content {
						padding: 30px 0;
					}
				}
			}
		}
	}

	footer {
		.footer-top {
			padding: 30px 20px;

			.logo {
				display: block;
				padding: 0;
				margin-bottom: 20px;
			}

			a.up {
				display: none;
			}

			.areas {
				width: 100%;
				display: block;
				clear: both;

				ul {
					li {
						margin: 0 24px 10px 0;

						&.title {
							display: block;
						}
					}
				}
			}
		}

		.footer-bottom {
			text-align: center;

			a.up.mobile {
				display: inline-flex;
				margin: 20px auto 0 auto;
			}
		}
	}
}

@media screen and (max-width: 720px) {
	.main-banner {
		&.form-banner,
		&.image-banner {
			.banner-content {
				h1 {
					@include font-size(22px);
				}

				a.button {
					display: table;
					margin-bottom: 20px;
				}

				a.button + a.button {
					margin-left: 0;
				}
			}
		}

		.banner-content {
			h1 {
				@include font-size(22px);
			}
		}
	}

	h1,
	h2 {
		@include font-size(22px);
	}

	h3 {
		@include font-size(18px);
	}

	.page-section {
		&.accreditations-section {
			.accreditations {
				ul {
					grid-template-columns: 1fr 1fr;
				}
			}
		}

		&.image-content-blocks {
			&:before {
				width: 50%;
			}

			.blocks-container {
				.block {
					.block-content {
						h2,
						h3 {
							@include font-size(22px);
						}

						a.button {
							margin-top: 20px;
						}
					}
				}
			}
		}

		&.services-section {
			.content-wrap {
				.service-icons {
					grid-template-columns: 1fr 1fr;

					.icon {
						p {
							@include font-size(12px);
						}
					}
				}

				.section-content {
					h2 {
						@include font-size(22px);
					}

					h3 {
						@include font-size(18px);
					}
				}
			}
		}
	}

	footer {
		nav.footer-menu {
			ul {
				grid-template-columns: 1fr 1fr;
			}
		}
	}
}

@media screen and (max-width: 680px) {
	.three-col {
		.grid-container {
			grid-template-columns: 1fr;

			.block {
				.block-image {
					.image {
						img {
							width: 100%;
						}
					}
				}
			}

			.no-cssgrid & {
				.block {
					width: 100%;
					margin: 0 0 50px 0;

					&:nth-child(2n + 2),
					&:nth-child(3n + 3) {
						width: 100%;
						margin: 0 0 50px 0;
					}
				}
			}
		}
	}

	.four-col {
		.grid-container {
			grid-template-columns: 1fr 1fr;

			.no-cssgrid & {
				.block {
					width: 48%;
					margin: 0 4% 50px 0;

					&:nth-child(3n + 3) {
						margin: 0 4% 50px 0;
					}

					&:nth-child(2n + 2) {
						margin-right: 0;
					}
				}
			}
		}
	}

	.page-section {
		.table-wrap {
			overflow: auto;

			table {
				min-width: 650px;
			}
		}

		&.image-content-blocks {
			.blocks-container {
				.block {
					.block-content {
						margin: -25px 20px 0 20px;
					}

					.block-images {
						.progress-bar {
							height: 10px;
						}
					}
				}
			}
		}
	}

	.search-results {
		.search-form {
			form {
				input[type="text"] {
					width: 100%;
				}
			}
		}
	}

	#gdpr-cookie-message {
		left: 0;
		right: 0;
		margin: auto;
	}
}

@media screen and (max-width: 580px) {
	.touchevents {
		.fadeIn,
		.fadeInUp,
		.fadeInLeft,
		.fadeInRight {
			opacity: 1 !important;
			visibility: visible !important;
			transition: none;
			animation: none;
		}
	}

	.mobile-menu {
		.menu-wrap {
			padding: 0;
		}
	}

	.four-col {
		.grid-container {
			grid-template-columns: 1fr;

			.no-cssgrid & {
				.block {
					width: 100%;
					margin: 0 0 50px 0;

					&:nth-child(3n + 3) {
						margin: 0 0 50px 0;
					}

					&:nth-child(2n + 2) {
						margin-right: 0;
					}
				}
			}
		}
	}

	/*form {
        button,
        input[type="submit"] {
            width: 100%;

            &:after {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
            }
        }
    }*/

	footer {
		nav.footer-menu {
			margin-bottom: 50px;

			ul {
				grid-gap: 30px;
				grid-template-columns: 1fr;

				li {
					width: 100%;
				}
			}
		}

		.footer-middle {
			padding: 50px 0;

			.accreditations {
				ul {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					grid-gap: 20px 20px;
				}
			}
		}
	}
}

/*
==================================
HEIGHT BASED MEDIA QUERY
==================================
*/

/* HEIGHT AND WIDTH */

@media (max-height: 780px) and (min-width: 1200px) {
	.main-banner {
		&.form-banner,
		&.image-banner {
			.banner-content {
				padding: 4% 5%;

				h1 {
					@include font-size(28px);
				}

				h2 {
					@include font-size(25px);
				}

				h3 {
					@include font-size(22px);
				}

				p {
					@include font-size(16px);
				}
			}
		}
	}
}

@media (max-height: 680px) and (min-width: 1200px) {
	//laptop(ish)
}
