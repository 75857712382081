#main-scrollbar {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.page-wrap {
    padding-top: 110px;
    height: 100%;
    position: relative;
    background-color: $white;
    
    &.white-grey {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 65%;
            background-color: $lightgrey;
        }
    }
}

.gallery {
    width: 100%;
    clear: both;
    
    &:after {
        content: "";
        height: 0;
        display: block;
        visibility: hidden;
        clear: both;
    }
    
    br {
        display: none !important;
    }
    
    .gallery-item {
        width: 48% !important;
        margin: 0 4% 4% 0 !important;
        
        &:nth-of-type(2n+2) {
            margin-right: 0 !important;
        }
        
        img {
            border: none !important;
            width: 100% !important;
        }
    }
}


/*
======================
SEARCH
======================
*/

.search-results {
    .search-form {
        width: 100%;
        margin-bottom: 30px;
        
        form {
            input[type="text"] {
                width: calc(95% - 150px);
                height: 50px;
                float: left;
                margin: 0 5% 20px 0;
                background-color: transparent;
            }
            
            input[type="submit"] {
                width: 150px;
                height: 50px;
                margin: 0;
                color: $darkblue;
                border-color: $darkblue;
                background-color: transparent;
            }
        }
    }
    
    .main-search-results {
        .search-result {
            width: 100%;
            padding-bottom: 50px;
            margin-bottom: 50px;
            border-bottom: 1px solid $darkblue;
            
            h3 {
                a {
                    color: $darkblue;
                    
                    .no-touchevents &:hover {
                        color: $red;
                    }
                }
            }
        }
        
        .pagination {
            width: 100%;
            padding-top: 20px;
            
            .prev {
                float: left;
            }
            
            .next {
                float: right;
            }
        }
    }
}


/*
=======================
GDPR COOKIE
=======================
*/

#gdpr-cookie-message {
	position: fixed;
	right: 50px;
	bottom: 50px;
	width: 100%;
	max-width: 300px;
	background-color: $white;
	padding: 30px 20px;
	z-index: 500;
	text-align: center;
	border-radius: 0;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
	
	h4 {
		color: $darkblue;
		margin-bottom: 10px;
    	line-height: 1.4;
        @include font-size(18px);
	}
	
	h5 {
		color: $darkblue;
		margin-bottom: 10px;
		font-family: $mainfont;
        line-height: 1.25rem;
        @include font-size(15px);
	}
	
	p, li {
		color: $darkblue;
		@include font-size(14px);
	}
	
	ul {
		padding: 0;
		
		li {
			width: 50%;
			display: inline-block;
			margin-bottom: 8px;
		}
	}
	
	a {
		color: $darkblue;
		display: block;
		text-decoration: underline;
		@include font-size(14px);
		font-family: $mainfont;
        font-weight: bold;
		margin-top: 10px;
		transition: 0.3s all;
		
		.no-touchevents &:hover {
			color: $darkblue;
			transition: 0.3s all;
		}
		
		&:focus {
			outline: 0;
		}
	}
	
	button {
		padding: 12px 25px;
		min-width: 160px;
		width: 100%;
		display: inline-block;
		font-family: $mainfont;
		text-align: center;
		cursor: pointer;
		@include font-size(14px);
		font-weight: bold;
		margin-bottom: 15px;
		color: $red;
		border: 1px solid $red;
		background-color: transparent;
		transition: 0.3s all;
		
		.no-touchevents &:hover {
			color: $darkblue;
			border-color: $darkblue;
		}
		
		&#gdpr-cookie-accept {
			color: $darkblue;
			margin-bottom: 0px;
			background-color: transparent;
			border: 1px solid $darkblue;
			
			.no-touchevents &:hover {
				color: $red;
				border-color: $red;
			}
		}
	}
	
	input[type="checkbox"] {
		position: relative;
		float: none;
		vertical-align: middle;
		cursor: pointer;
	}
	
	#gdpr-cookie-types {
		text-align: left;
		
		label {
			vertical-align: middle;
		}
	}
	
	#gdpr-cookie-types + p {
		margin: 10px 0 0 0;
	}
}