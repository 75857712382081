// Slider
.main-banner {
	width: 100%;
	padding-top: 20px;

	&.form-banner,
	&.image-banner {
		.banner-content {
			padding: 5%;
			color: $white;

			h1,
			h2,
			h3,
			h4,
			h5,
			p,
			li,
			a {
				color: $white;
			}

			h1 {
				line-height: 1.35;
				letter-spacing: normal;
			}

			p {
				@include font-size(18px);
				line-height: 1.5;
				font-weight: 500;
				margin-bottom: 30px;
			}

			a.button + a.button {
				margin-left: 20px;
			}

			.content-wrap {
				& > *:last-child {
					margin-bottom: 0; //
				}
			}
		}

		.banner-image {
			width: 100%;
			height: calc(100% - 90px);
			position: relative;
			padding: 0 100px;

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				font-family: "object-fit: cover;";
			}
		}
	}

	&.image-banner {
		overflow: hidden;
		height: calc(100vh - 110px);

		.banner-content {
			position: absolute;
			bottom: 40px;
			width: 100%;
			max-width: 42%;
			background-color: $red;

			&.fade {
				animation: fadeIn 1s;
				animation-delay: 0.4s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}

			&.red-bg {
				background-color: $red;
			}

			&.blue-bg {
				background-color: $darkblue;
			}
		}
	}

	&.form-banner {
		.banner-content {
			position: relative;
			z-index: 10;
			width: 100%;
			padding: 6%;
			max-width: 1010px;
			margin: -30vh auto 0 auto;
			background-color: $darkblue;
		}

		.banner-image {
			overflow: hidden;

			img {
				max-height: 65vh;
			}
		}
	}

	&.text-banner {
		padding: 50px 0;

		.banner-content {
			width: 100%;
			max-width: 50%;
			padding-right: 40px;
		}

		.page-template-page-faqs & {
			.container {
				max-width: 850px;

				.banner-content {
					max-width: 100%;
				}
			}
		}
	}

	&.post-banner {
		padding-top: 50px;

		.banner-content {
			width: 100%;
			position: relative;

			h1 {
				color: $red;
			}

			.post-cats {
				p {
					font-weight: bold;
					text-transform: uppercase;
					@include font-size(14px);
				}
			}

			.social-share {
				position: absolute;
				top: 0;
				left: -60px;
			}
		}

		.banner-image {
			margin-bottom: 50px;
		}
	}
}

.main-banner + footer {
	margin-top: 120px;
}
