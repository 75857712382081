/* Scss Document */

//loading
@-webkit-keyframes loading {
	0% {
        transform: rotate(0deg);
	}
	100% {
        transform: rotate(360deg);
	}
}
@keyframes loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

//fadein
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.fadeIn {
    opacity: 0;
	transition: all 0.7s;
	animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity : 1;
	}  
	100% {
		opacity : 0;
	}  
}
@keyframes fadeOut {
	0% {
		opacity : 1;
	}  
	100% {
		opacity : 0;
	}  
}

.fadeOut  {
    opacity: 0;
	transition: all 0.7s;
    animation-name: fadeOut;
	animation-duration: 0.8s;
}

//fadein up
@-webkit-keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 12%, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
}
@keyframes fadeInUp {
	from {
		opacity: 0;
        transform: translate3d(0, 12%, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

.fadeInUp {
    opacity: 0;
	transition: all 0.7s;
	animation-name: fadeInUp;
	animation-duration: 1.8s;
}

//fadein left
@-webkit-keyframes fadeInLeft {
	from {
		opacity: 0;
		transform: translate3d(-8%, 0, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInLeft {
  from {
		opacity: 0;
		transform: translate3d(-8%, 0, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fadeInLeft {
    opacity: 0;
	transition: all 0.7s;
	animation-name: fadeInLeft;
	animation-duration: 1.8s;
}

//fadein right
@-webkit-keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translate3d(8%, 0, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translate3d(8%, 0, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fadeInRight {
    opacity: 0;
	transition: all 0.7s;
	animation-name: fadeInRight;
	animation-duration: 1.8s;
}

.wow,
.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
}