/* MIXINS */
@use "sass:math";

//appearance
@mixin appearance($value) {
	-webkit-appearance: $value;
	-moz-appearance: $value;
	appearance: $value;
}

//PX TO REMS
@function calculateRem($size) {
	$remSize: math.div($size, 16px);
	@return #{$remSize}rem;
}
@mixin font-size($size) {
	font-size: $size; //Fallback in px
	font-size: calculateRem($size);
}

//calc
@mixin calc($property, $expression) {
	#{$property}: -moz-calc(#{$expression});
	#{$property}: -webkit-calc(#{$expression});
	#{$property}: calc(#{$expression});
}

//lineclamp
@mixin line-clamp {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	line-clamp: 4;
	-webkit-box-orient: vertical;
	box-orient: vertical;
	overflow: hidden;
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		@content;
	}
	&::-moz-placeholder {
		/* Firefox 19+ */
		@content;
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		@content;
	}
	&:-moz-placeholder {
		/* Firefox 18- */
		@content;
	}
	&::placeholder {
		@content;
	}
}
