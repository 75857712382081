header {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	height: 110px;
	padding: 20px;
	z-index: 100;
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
	-webkit-backface-visibility: hidden;
    
    .logo {
        width: 150px;
        height: 60px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        text-align: center;
        
        a {
            display: block;
            
            &.mobile-emergency {
                display: none;
                margin-bottom: 20px;
                text-transform: uppercase;
                color: $red;
                font-weight: bold;
                line-height: 1.2;
                letter-spacing: normal;
                @include font-size(13px);
                
                .no-touchevents &:hover {
                    color: $darkblue;
                }
            }
        }
    }
    
    .header-col {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        
        &.header-left {
            padding-right: 9%;
            
            .header-icon {
                img {
                    max-height: 45px;
                }
            }
        }
        
        &.header-right {
            padding-left: 9%;
        }
    }
    
    .telephone {
        a {
            border: 1px solid $red;
            padding: 10px;
            color: $red;
            font-weight: bold;
            display: block;
            background-color: transparent;
            font-family: $mainfont;
        }
        
        &.mobile-tel {
            display: none;
            
            a {
                width: 38px;
                height: 38px;
                padding: 0;
                color: $white;
                background-color: $red;
                text-align: center;
                border-radius: 50%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                .no-touchevents &:hover {
                    background-color: $darkblue;
                    border-color: $darkblue;
                }
            }
        }
    }
}


/*
=================================
HAMBURGER / FLYOUT MENU
=================================
*/

.hamburger {
	cursor: pointer;
	width: 32px;
	position: absolute;
	z-index: 150;
	top: 25px;
	left: 30px;
	display: none;
	transition: all 0.4s;

	span {
		position: relative;
		top: 0;
		width: 100%;
		height: 3px;
		display: block;
		margin: 0 auto 6px auto;
		background-color: $red;
		transition: transform 0.3s ease-in-out, opacity 0.3s 0.3s ease-in-out, top 0.3s 0.3s ease-in-out;

		&.bottom {
			margin-bottom: 0; 
		}
	}

	/*&.active span {
		transition: top 0.3s ease-in-out, opacity 0.3s 0.3s ease-in-out, transform 0.3s 0.3s ease-in-out;

		&.top {
			top: 9px;
			transform: rotate(45deg);
		}

		&.middle {
			opacity: 0;
		}

		&.bottom {
			top: -9px;
			transform: rotate(-45deg);
		}
	}*/
}