/* Scss Document */

/*
======================
MAIN CSS RESETS
======================
*/

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

article, aside, figure, figcaption, footer, header, hgroup, nav, section, video, audio, summary {
	display: block;
}

html, body, div, span,
h1, h2, h3, h4, h5, h6, p, blockquote, 
a, cite, code, dfn, em, img, ol, ul, li,
fieldset, form, label, textarea, textfield, caption,
article, aside, figure, figcaption, footer, header, nav, section, summary, audio, video, summary {
	margin: 0;
	padding: 0;
	border: 0;	
}

html,
body { 
	font-size: 16px;
	font-family: $mainfont;
    text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-overflow-scrolling: touch;		
}

/*html {
    scroll-behavior: smooth;
}*/

body {
	position: relative;
	opacity: 1;
	transition: 0.8s opacity;
    overflow-x: hidden !important;
	
	&.fade-out {
		opacity: 0;
	}
}

/* Change colour of text/background in highlighted text */
::-moz-selection {
    color: $white;
    text-shadow: none;
    background-color: #0064AA;
}

::selection {
    color: $white;
    text-shadow: none;
    background-color: #0064AA;
}

:focus {
	outline: none;
}

img {
	vertical-align: top;
	border: none;
	max-width: 100%;
	height: auto;
	
	&.alignleft {
		float: left;
		margin-right: 30px;
	}
	
	&.alignright {
		float: right;
		margin-left: 30px;	
	}
	
	&.aligncenter {
		margin-bottom: 30px;
		margin: 0 auto 30px auto;	
	}
}

ul:after,
.clearfix:after {
	content: "";
    height: 0;
    display: block;
    visibility: hidden;
    clear: both;	
}

ol li,
ul li {
	list-style-position: inside;	
}

ul li {
	list-style-type: none;	
}

a {
	text-decoration: none;
}

input[type="button"],
input[type="submit"],
input[type="tel"],
input[type="email"],
input[type="text"],
textarea {
	border-radius: 0;
	@include appearance(none);
}

h1,h2,h3,h4,h5 {
	margin-bottom: 20px;
}

.textwidget p:last-child {
	margin: 0;
}

.hidden {
	opacity: 0;
}

.visible {
	opacity: 1;
}

.centered {
	position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
}

.cover {
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	-ms-background-size: cover;
	background-size: cover;
	background-position: center center; 
}

.clear {
	display: block;
	width: 100%;
	clear: both;
	height: 0;
}