.blog-cats {
    width: 100%;
    
    ul {
        li {
            margin-right: 20px;
            display: inline-block;
            
            a {
                color: $darkblue;
                font-weight: bold;
                text-transform: uppercase;
                @include font-size(15px);
                
                .no-touchevents &:hover {
                    color: $red;
                }
            }
            
            &.current-cat {
                a {
                    color: $red;
                }
            }
        }
    }
}

.news-posts {
	width: 100%;
    padding-top: 50px;
    
    .block {
        .excerpt,
        .post-meta {
            p {
                @include font-size(14px);
            }
        }
        
        .post-meta {
            p {
                margin-bottom: 5px;
            }
        }
    }
}

.question-answer {
    border: 1px solid $darkblue;
    padding: 20px;
    margin-bottom: 30px;
    
    p {
        font-weight: bold;
        @include font-size(18px);
        
        &:last-child {
            margin-bottom: 0;
        }
        
        .red-text {
            font-weight: bold;
        }
    }
}

.load-more-posts {
	width: 100%;
	clear: both;
	min-height: 80px;
	text-align: center;
	padding: 50px 0;
    grid-column: span 3;
    grid-column: 1/-1;
	
	a {
		display: inline-block;
	}
	
	.load {
		width: 55px;
		height: 55px;
		border-radius: 50%;
		clear: both;
		display: inline-block;
		position: relative;
		text-indent: -9999em;
		border-top: 5px solid $lightgrey;
		border-right: 5px solid $lightgrey;
		border-bottom: 5px solid $lightgrey;
		border-left: 5px solid $red;
		animation: loading 1s infinite linear;
	}
}

.single-post {
    .post-content {
        padding-top: 0;
    }
}

.social-share {
    ul {
        li {
            margin-bottom: 15px;
            display: block;
            padding: 0;
            
            &:before {
                content: none !important;
            }
            
            &:last-child {
                margin-bottom: 0;
            }

            a {
                width: 36px;
                height: 36px;
                display: block;
                color: $red;
                border: 1px solid $red;
                border-radius: 50%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                i {
                    margin: 1px !important;
                    position: relative;
                    top: 1px;
                }

                .no-touchevents &:hover {
                    color: $darkblue;
                    border-color: $darkblue;
                }
            }
        }
    }
}